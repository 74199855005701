import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/2fa',
    name: 'two-factor',
    component: () => import(/* webpackChunkName: "2fa" */ '../views/two-factor.vue')
  },
  {
    path: '/homeview',
    name: 'Home',
    component: () => import(/* webpackChunkName: "homeview" */ '../views/HomeView.vue'),
    children: [
      {
        path: '/starofthemonth',
        name: 'Star of the month',
        component: () => import(/* webpackChunkName: "starofthemonth" */ '../views/StarOfTheMonth.vue')
      },
      {
        path: '/OtherNews',
        name: 'Other News',
        component: () => import(/* webpackChunkName: "OtherNews" */ '../views/OtherNews.vue')
      },
      {
        path: '/ManageOtherNews',
        name: 'Manage Other News',
        component: () => import(/* webpackChunkName: "ManageOtherNews" */ '../views/ManageOtherNews.vue')
      },
      {
        path: '/RecentPromotions',
        name: 'Recent Promotions',
        component: () => import(/* webpackChunkName: "RecentPromotions" */ '../views/RecentPromotions.vue')
      },
      {
        path: '/LoadBanner',
        name: 'Load Banner',
        component: () => import(/* webpackChunkName: "LoadBanner" */ '../views/LoadBanner.vue')
      },
      {
        path: '/LoadEvent',
        name: 'Load Event',
        component: () => import(/* webpackChunkName: "LoadEvent" */ '../views/LoadEvent.vue')
      },
      {
        path: '/ManageEvents',
        name: 'Manage Events',
        component: () => import(/* webpackChunkName: "ManageEvents" */ '../views/ManageEvents.vue')
      },
      {
        path: '/LoadArticle',
        name: 'Load Article',
        component: () => import(/* webpackChunkName: "LoadArticle" */ '../views/LoadArticle.vue')
      },
      {
        path: '/ManageArticles',
        name: 'Manage Articles',
        component: () => import(/* webpackChunkName: "ManageArticles" */ '../views/ManageArticles.vue')
      },
      {
        path: '/LoadLinks',
        name: 'Load Links',
        component: () => import(/* webpackChunkName: "LoadLinks" */ '../views/LoadLinks.vue')
      },
      {
        path: '/ManageLinks',
        name: 'Manage Links',
        component: () => import(/* webpackChunkName: "ManageLinks" */ '../views/ManageLinks.vue')
      },
      {
        path: '/LoadAdverts',
        name: 'Load Adverts',
        component: () => import(/* webpackChunkName: "LoadArticle" */ '../views/LoadAdverts.vue')
      },
    
    ],
  },

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => {
  console.log(to)
  if(to.path === "/" || to.path === "/2fa" ){
    return true;
  }

  if(localStorage.getItem("userDetails") !== null) {
    return true
  }

window.location = "/"
  return false
})

export default router
